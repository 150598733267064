<template>
  <el-dialog top="3vh" :visible.sync="showDialog" width="760px">
    <div slot="title" style="text-align: center" class="slot-title">
      <span style="background: #eceafa; padding: 5px"> {{ this.title }}</span>
    </div>
    <div class="dialog">
      <div class="flex-row flex-ac">
        <div class="title">
          <span style="color: red; font-size: 16px">*</span>参数名称
        </div>
        <el-input
          v-model="form.name"
          style="width: 200px"
          size="mini"
          placeholder="请输入"
          :disabled="disabled"
        ></el-input>
      </div>
      <div class="flex-row flex-ac">
        <div class="title">备注名称</div>
        <el-input
          v-model="form.remark"
          style="width: 200px"
          size="mini"
          placeholder="请输入"
        ></el-input>
      </div>
      <div class="flex-row flex-ac">
        <div class="title">
          <span style="color: red; font-size: 16px">*</span>跳转页面
        </div>
        <el-select
          :disabled="disabled"
          style="width: 200px"
          size="mini"
          v-model="form.relevanceFlag"
          class="m-r-10"
        >
          <el-option
            v-for="(option, index) in pushPages"
            :key="index"
            :label="option.label"
            :value="option.value"
          ></el-option>
        </el-select>
        <el-select
          :disabled="disabled"
          style="width: 200px"
          size="mini"
          v-model="form.targetFlag"
          placeholder="请选择"
        >
          <el-option
            v-for="(option, index) in selectItems"
            :key="index"
            :label="option.scenarioName"
            :value="option.id"
          ></el-option>
        </el-select>
      </div>
      <div class="flex-row flex-ac">
        <div class="title">
          <span style="color: red; font-size: 16px">*</span>落地页
        </div>
        <el-popover
          style="width: 200px"
          trigger="hover"
          placement="right"
          width="330"
        >
          <div
            v-if="chooseTransferPage"
            class="transfer-popover flex-row space-between"
          >
            <img
              style="width: 135px; height: 248px"
              :src="chooseTransferPage.bottomUrl"
              alt=""
            />
            <div>
              <div class="flex-cloumn flex-ac">
                <img
                  style="width: 137px; height: 64px"
                  :src="chooseTransferPage.freeButtonUrl"
                  alt=""
                />
                <p>免费按钮</p>
              </div>
              <div class="flex-cloumn flex-ac">
                <img
                  style="width: 137px; height: 64px"
                  :src="chooseTransferPage.paidButtonUrl"
                  alt=""
                />
                <p>付费按钮</p>
              </div>
            </div>
          </div>
          <el-select
            style="width: 200px"
            slot="reference"
            size="mini"
            v-model="form.transferId"
          >
            <el-option
              v-for="(option, index) in transferPages"
              :key="index"
              :label="option.name"
              :value="option.id"
            ></el-option>
          </el-select>
        </el-popover>
      </div>
      <div class="flex-row flex-ac">
        <div class="title">
          <span style="color: red; font-size: 16px">*</span>数量
        </div>
        <el-input
          v-model="form.linkQuantity"
          oninput="if(value<1)value=1"
          style="width: 65px; margin-right: 20px"
          size="mini"
          type="number"
          :disabled="disabled"
        ></el-input>
      </div>
      <div v-if="disabled" class="flex-row flex-ac">
        <div class="title">
          <span style="color: red; font-size: 16px">*</span>链接
        </div>
        <div>
          {{ form.link }}
        </div>
        <el-button class="m-l-10" type="primary" size="mini" v-copy="form.link"
          >复制链接</el-button
        >
      </div>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button
        @click="delUrl"
        v-if="disabled"
        :loading="loading"
        size="mini"
        type="danger"
        >删除</el-button
      >
      <el-button :loading="loading" size="mini" @click="addUrl" type="primary">
        {{ buttonTitle }}</el-button
      >
    </span>
  </el-dialog>
</template>

<script>
// import { deepCopy } from "@tools";
import { formRule } from "@tools";
export default {
  props: {
    detail: {
      default: null,
    },
  },
  data() {
    this.pushPages = [
      { label: "首页", value: 1 },
      { label: "剧本详情页", value: 4 },
      { label: "选角色页/游戏内页", value: 2 },
      { label: "我的", value: 3 },
    ];
    this.formRule = [
      {
        label: "name",
        name: "参数名称",
        rule: {
          required: true,
        },
      },
      {
        label: "relevanceFlag",
        name: "跳转页面",
        rule: {
          required: true,
        },
      },
      {
        label: "targetFlag",
        name: "跳转页面",
        rule: {
          required: true,
        },
      },
      {
        label: "transferId",
        name: "落地页",
        rule: {
          required: true,
        },
      },
      {
        label: "linkQuantity",
        name: "数量",
        rule: {
          required: true,
        },
      },
    ];
    return {
      showDialog: false,
      loading: false,
      form: {
        name: "",
        remark: "",
        relevanceFlag: 2,
        linkQuantity: 1,
        transferId: "",
        targetFlag: "",
      },
      transferPages: [],
      scripts: []
    };
  },
  created() {
    this.getScriptList();
    this.getTransferPages();
  },
  methods: {
    async addUrl() {
      if (!formRule(this.form, this.formRule)) return;
      this.loading = true;
      let params = {};
      if (this.detail) {
        const { id, remark, transferId } = this.form;
        params = { id, remark, transferId };
      } else {
        params = this.form;
      }
      await this.$api.market[this.detail ? "editUrl" : "addUrl"](
        params
      ).finally(() => {
        this.loading = false;
      });
      this.showDialog = false;
      this.$message.success(this.detail ? "修改链接成功！" : "创建链接成功！");
      this.$emit("getUrlList");
    },
    delUrl() {
      this.$confirm("此操作将永久删除该链接, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(async () => {
        await this.$api.market.delUrl(this.detail.id);
        this.showDialog = false;
        this.$message.success("删除链接成功");
        this.$emit("getUrlList");
      });
    },
    async getScriptList() {
      const data = await this.$api.market.getHavePriceScenario();
      this.scripts = data;
    },
    async getTransferPages() {
      const data = await this.$api.market.getTransferPage({});
      this.transferPages = data;
    },
  },
  computed: {
    title() {
      return this.detail ? "修改链接" : "新建链接";
    },
    buttonTitle() {
      return this.detail ? "保存" : "生成链接";
    },
    chooseTransferPage() {
      return this.transferPages.find(({ id }) => id === this.form.transferId);
    },
    disabled() {
      return Boolean(this.detail);
    },
    selectItems () {
      const relevanceFlag = this.form.relevanceFlag
      let arr = []
      if (relevanceFlag === 2 || relevanceFlag === 4) {
        arr = this.scripts
      } else if (relevanceFlag === 1) {
        arr = [
          { scenarioName: '-', id: '/pages/home/home' }
        ]
      } else if (relevanceFlag === 3) {
        arr = [
          { scenarioName: '商城', id: '/package2/pages/shop/shop' }
        ]
      }
      return arr
    }
  },
  watch: {
    showDialog(newVal) {
      if (!newVal) {
        this.form = this.$options.data().form;
      } else {
        this.detail && Object.assign(this.form, this.detail);
      }
    },
    "form.relevanceFlag": function (val) {
      const obj = {
        '1': '/pages/home/home',
        '2': this.detail.targetFlag,
        '3': '/package2/pages/shop/shop',
        '4': this.detail.targetFlag
      }
      this.form.targetFlag = obj[val]
    },
  },
};
</script>

<style scoped lang="scss">
@import "@style/form.scss";
</style>