<template>
  <div class="btn-wrap flex-row flex-ac space-between flex-ac">
    <el-select
      size="mini"
      style="width: 120px"
      placeholder="排序方式"
      v-model="filter.sort"
    >
      <el-option
        v-for="(item, index) in sorts"
        :key="index"
        :label="item.label"
        :value="item.value"
      ></el-option>
    </el-select>
    <div class="flex-row flex-ac">
      <div class="flex-row m-r-10">
        <div class="btn-text">流量主名单：</div>
        <div size="mini" class="btn pointer" @click="showFlow">编辑</div>
      </div>
      <el-input
        v-model="filter.searchName"
        style="width: 200px"
        placeholder="搜索ID/用户名称"
        size="mini"
      />
    </div>
    <flow-dialog ref="flow" @change="change" />
  </div>
</template>

<script>
import FlowDialog from "./flowDialog.vue";
export default {
  components: {
    FlowDialog,
  },
  data() {
    this.sorts = [
      { value: 1, label: "授权降序" },
      { value: 2, label: "付费额降序" },
      { value: 3, label: "日期降序" },
    ];
    return {
      filter: {
        sort: 3,
        searchName: "",
      },
    };
  },
  methods: {
    showFlow() {
      this.$refs.flow.dialogVisible = true;
    },
    change() {
      this.$emit("filter", this.filter);
    }
  },
  watch: {
    filter: {
      deep: true,
      handler(val) {
        this.$emit("filter", val);
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.btn {
  background: #7f76bd;
  width: 96px;
  height: 24px;
  border-radius: 3px;
  color: #fff;
  text-align: center;
  padding: 0 !important;
}
.btn-text {
  background: #fff;
}
</style>