<template>
  <div class="h-100">
    <div class="flex-cloumn h-100">
      <div class="tab">
        <!-- tab栏 -->
        <div
          class="tab-item"
          v-for="item in tabMenu"
          :key="item.id"
          :class="curTab === item.id ? 'tab-item-active' : ''"
          @click="changeTab(item.id)"
        >
          {{ item.name }}
        </div>
      </div>
      <!-- 筛选表单 -->
      <div class="btn-wrap flex-row flex-ac" v-if="curTab === 1">
        <el-input
          style="width: 130px; margin-right: 20px"
          size="mini"
          placeholder="搜索关键字"
          v-model="tableFilter1.searchName"
          @input="getUrlList(true)"
        />
        <div @click="openDialog('addUrl')" class="creatNewBtn">新建链接</div>
      </div>
      <fission-filter v-if="curTab === 2" @filter="getFissions" />
      <div class="row2 flex-1" v-loading="loading">
        <el-card class="row2-card">
          <!-- 表头 -->
          <div v-if="curTab === 1">
            <div class="flex-row table-header" style="padding-left: 40px">
              <div style="width: 150px; text-align: left">参数名称</div>
              <div style="width: 180px">备注名称</div>
              <div style="width: 130px">点击次数(次)</div>
              <div style="width: 100px">新增(人)</div>
              <div style="width: 100px">次活(人)</div>
              <div style="width: 100px">周活(人)</div>
              <div style="width: 100px">月活(人)</div>
              <div style="width: 100px">付费(人)</div>
              <div style="width: 110px">付费额(元)</div>
              <div style="width: 100px">操作</div>
              <div
                style="width: 150px; padding-left: 10px"
                class="relative-left-10"
              >
                生成日期
              </div>
            </div>
            <!-- 行数据 -->
            <div
              class="item"
              @click="openDialog('addUrl', item)"
              v-for="(item, i) in list"
              :key="i"
              :style="{
                background: i % 2 !== 0 ? '#fff' : 'rgba(250,249,255,1)',
              }"
            >
              <!-- <el-checkbox></el-checkbox> -->
              <!-- 商品序号 -->
              <div class="item-index">
                {{ i + 1 }}
              </div>
              <div class="flex-row space-around flex-1">
                <!-- 名称 -->
                <div class="item-title" style="width: 100px">
                  {{ item.name }}
                </div>
                <div class="item-message shenglue-1" style="width: 120px">
                  {{ item.remark }}
                </div>
                <div
                  class="item-message shenglue-1"
                  style="width: 50px; text-align: center"
                >
                  {{ item.clickQuantity }}
                </div>
                <div
                  class="item-message shenglue-1"
                  style="width: 50px; text-align: center"
                >
                  {{ item.increaseQuantity }}
                </div>
                <div
                  class="item-message shenglue-1"
                  style="width: 50px; text-align: center"
                >
                  {{ item.activeQuantity }}
                </div>
                <div
                  class="item-message shenglue-1"
                  style="width: 55px; text-align: center"
                >
                  {{ item.weekActiveQuantity }}
                </div>
                <div
                  class="item-message shenglue-1"
                  style="width: 60px; text-align: center"
                >
                  {{ item.monthActiveQuantity }}
                </div>
                <div
                  class="item-message shenglue-1"
                  style="width: 50px; text-align: center"
                >
                  {{ item.paidQuantity }}
                </div>
                <div
                  class="item-message shenglue-1"
                  style="width: 60px; text-align: center"
                >
                  {{ item.sales }}
                </div>
                <div
                  class="position-btn bg"
                  style="text-align: center"
                  v-copy="item.link"
                  @click.stop
                >
                  复制链接
                </div>
                <div class="item-date shenglue-1" style="width: 80px">
                  <div class="state">
                    {{ item.generatorTime }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div v-if="curTab === 2">
            <div class="flex-row table-header" style="padding-left: 40px">
              <div
                v-for="(item, index) in fissionHeader"
                :key="index"
                :style="item.style"
              >
                {{ item.name }}
              </div>
            </div>
            <div
              class="item"
              @click="toFissionDetail(item)"
              v-for="(item, i) in list2"
              :key="i"
              :style="{
                background: i % 2 !== 0 ? '#fff' : 'rgba(250,249,255,1)',
              }"
            >
              <div class="item-index">
                {{ i + 1 }}
              </div>
              <div
                v-for="(item2, index2) in fissionBody"
                :key="index2"
                :class="item2.class"
                :style="item2.style"
              >
                <span v-if="index2 !== 7">{{ item[item2.key] }}</span>
                <div @click.stop v-else v-copy="item.code">{{ item2.key }}</div>
              </div>
            </div>
            <div class="page-text" v-if="showPageText">请翻页</div>
          </div>
        </el-card>
      </div>
    </div>
    <div class="row3" v-if="curTab === 1">
      <el-pagination
        :total="total"
        :current-page="tableFilter1.currPage"
        :page-size="tableFilter1.limit"
        layout="sizes, prev, pager, next"
        :page-sizes="[5, 10, 15, 20]"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      ></el-pagination>
    </div>
    <div class="row3" v-if="curTab === 2">
      <el-pagination
        :total="total2"
        :current-page="tableFilter2.currPage"
        :page-size="tableFilter2.limit"
        layout="prev, pager, next"
        :page-sizes="[5, 10, 15, 20]"
        @current-change="handleCurrentChange"
      ></el-pagination>
    </div>
    <add-url ref="addUrl" :detail="detail" @getUrlList="getUrlList" />
  </div>
</template>
<script>
import addUrl from "./common/components/addUrl";
import FissionFilter from "./common/components/fissionFilter.vue";
import { debounce } from "@tools";
export default {
  components: {
    addUrl,
    FissionFilter,
  },
  data() {
    this.fissionHeader = [
      {
        name: "用户名称",
        style: { width: "300px", textAlign: "left" },
        class: "",
      },
      { name: "访问(次)", style: { width: "100px" }, class: "" },
      { name: "授权(人)", style: { width: "100px" }, class: "" },
      { name: "游戏(人)", style: { width: "100px" }, class: "" },
      { name: "付费(人)", style: { width: "100px" }, class: "" },
      { name: "付费额(元)", style: { width: "150px" }, class: "" },
      { name: "ID", style: { width: "150px" }, class: "" },
      { name: "操作", style: { width: "150px" }, class: "" },
      { name: "授权日期", style: { width: "200px" }, class: "" },
    ];
    this.fissionBody = [
      {
        key: "nickName",
        style: { width: "300px" },
        class: "item-message shenglue-1",
      },
      {
        key: "visitQuantity",
        style: { width: "100px" },
        class: "item-message shenglue-1",
      },
      {
        key: "authQuantity",
        style: { width: "100px" },
        class: "item-message shenglue-1",
      },
      {
        key: "gameQuantity",
        style: { width: "100px" },
        class: "item-message shenglue-1",
      },
      {
        key: "paidQuantity",
        style: { width: "80px" },
        class: "item-message shenglue-1",
      },
      {
        key: "paidSales",
        style: { width: "150px" },
        class: "item-message shenglue-1",
      },
      {
        key: "code",
        style: { width: "130px" },
        class: "item-message shenglue-1",
      },
      {
        key: "复制ID",
        style: { width: "110px", textAlign: "center" },
        class: "position-btn bg",
      },
      {
        key: "authTime",
        style: { width: "200px", textAlign: "center" },
        class: "item-message shenglue-1",
      },
    ];
    return {
      loading: false,
      // tab栏
      curTab: 1,
      tabMenu: [
        { id: 1, name: "链接" },
        { id: 2, name: "裂变" },
      ],
      // 链接筛选
      tableFilter1: {
        currPage: 1,
        limit: 10,
        searchName: "",
      },
      // 裂变筛选
      tableFilter2: {
        currPage: 1,
        limit: 10,
        sort: 3,
        searchName: "",
      },
      list: [], // 链接列表
      list2: [], // 裂变列表
      detail: null,
      total: 0,
      total2: 0,
    };
  },
  created() {
    if (this.$route.params.tab === 2) {
      this.changeTab(2);
    } else {
      this.getUrlList();
    }
  },
  methods: {
    getUrlList: debounce(async function (isSearch) {
      if (isSearch) {
        this.tableFilter1.currPage = 1;
      }
      this.loading = true;
      const data = await this.$api.market
        .getUrlList(this.tableFilter1)
        .finally(() => {
          this.loading = false;
        });
      this.list = data.data;
      this.total = data.count;
    }, 300),
    getFissions: debounce(async function (filter) {
      Object.assign(this.tableFilter2, filter);
      this.loading = true;
      const data = await this.$api.market
        .getFissionList(this.tableFilter2)
        .finally(() => {
          this.loading = false;
        });
      this.list2 = data.data;
      this.total2 = data.count;
      if (data.data.length === 0 && data.count !== 0) {
        this.getFissions({ currPage: this.tableFilter2.currPage - 1 })
      }
    }, 300),
    handleSizeChange(size) {
      this[`tableFilter${this.curTab}`].limit = size;
      this[this.curTab === 1 ? "getUrlList" : "getFissions"]();
    },
    handleCurrentChange(page) {
      this[`tableFilter${this.curTab}`].currPage = page;
      this[this.curTab === 1 ? "getUrlList" : "getFissions"]();
    },
    openDialog(ref, detail = null) {
      this.detail = detail;
      this.$refs[ref].showDialog = true;
    },
    changeTab(id) {
      this.curTab = id;
      this[id === 1 ? "getUrlList" : "getFissions"]();
    },
    toFissionDetail(item) {
      this.$router.push({path: "/fission", query: { id: item.id }});
    },
  },
  watch: {
    // tableFilter1: {
    //   handler() {
    //     this.getUrlList();
    //   },
    //   deep: true,
    // },
  },
  computed: {
    showPageText() {
      const { currPage, limit } = this.tableFilter2;
      return this.total2 !== 0 && Math.ceil(this.total2 / limit) !== currPage;
    },
  },
};
</script>
<style lang="scss" scoped>
@import "./common/style/market.scss";
@import "./common/style/form.scss";
.item-date {
  text-align: left;
}
.page-text {
  color: #999999;
  display: flex;
  justify-content: center;
  font-size: 14px;
  margin-top: 10px;
}
</style>