<!--  -->
<template>
  <el-dialog title="流量主" :visible.sync="dialogVisible" width="768px">
    <div style="display: flex; padding: 0 30px">
      <div class="left-wrap">
        <div style="padding: 3px">搜索用户</div>
        <div style="display: flex">
          <el-input
            size="mini"
            placeholder="请输入ID/用户名"
            v-model="inputValue"
            @keydown.enter.native="searchUserList()"
          >
          </el-input>
          <el-button size="mini" @click="searchUserList()" type="primary"
            >搜索</el-button
          >
        </div>
        <div v-loading="userListLoading" class="left-list">
          <div
            v-for="(item, index) in userList"
            :key="index"
            class="flex space-between mt-3"
          >
            <div>{{ item.name }}</div>
            <div class="add-btn pointer" @click="addUserIgnore(item)"></div>
          </div>
        </div>
        <div class="flex-cc">
          <el-pagination
            :total="userPagination.count"
            :current-page="userPagination.curPage"
            :page-size="userPagination.size"
            :pager-count="5"
            @current-change="searchUserList"
            layout="prev, pager, next"
          ></el-pagination>
        </div>
      </div>
      <div style="width: 60%">
        <!-- 表头 -->
        <div style="padding-left: 10px">已选名单</div>
        <div class="flex space-between text-c">
          <div style="min-width: 18px"></div>
          <div style="width: 30%">用户名称</div>
          <div style="width: 54px">ID</div>
          <div style="width: 50px">操作</div>
        </div>
        <!-- 行数据 -->
        <div
          v-loading="ignoreListLoading"
          style="height: 361px; overflow-y: scroll"
        >
          <div
            class="item"
            v-for="(item, i) in ignoreList"
            :key="i"
            :style="{
              background: i % 2 !== 0 ? '#fff' : 'rgba(250,249,255,1)',
            }"
          >
            <!-- 商品序号 -->
            <div class="item-index">
              {{ i + 1 }}
            </div>
            <div class="shenglue" style="width: 30%">
              {{ item.nickName }}
            </div>

            <div class="other">{{item.code}}</div>
            <div class="del-btn" @click="delUserIgnore(item.id)">删除</div>
          </div>
        </div>
        <div class="flex-cc">
          <el-pagination
            :total="ignorePaginationCount"
            :current-page="ignorePagination.currPage"
            :page-size="ignorePagination.limit"
            :pager-count="5"
            @current-change="getUserIgnore"
            layout="prev, pager, next"
          ></el-pagination>
        </div>
      </div>
    </div>

    <div slot="title" class="flex-row flex-cc">
      <div
        class="dia-title flex-cc"
      >
        流量主
      </div>
    </div>
  </el-dialog>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      type: 1,
      dialogVisible: false,
      userPagination: {
        count: 10,
        curPage: 1,
        limit: 10,
        name: "",
      },
      ignorePagination: {
        currPage: 1,
        limit: 10
      },
      ignoreList: [],
      inputValue: "",
      userList: [],
      userListLoading: false,
      ignoreListLoading: false,
      ignorePaginationCount: 0,
    };
  },
  computed: {},
  mounted() {
    this.getUserIgnore();
  },
  watch: {
    dialogVisible(v) {
      !v && this.$emit("change");
    },
  },
  methods: {
    openDialog() {
      this.dialogVisible = true;
      this.$forceUpdate();
    },
    async getUserIgnore(page) {
      page && (this.ignorePagination.currPage = page);
      const param = this.ignorePagination;
      this.ignoreListLoading = true;
      try {
        const res = await this.$api.market.getFissionList(param);
        // 删除后如果本页为空，则跳到前一页
        if (res.data.length === 0 && this.ignorePagination.currPage > 1) {
          this.ignorePagination.currPage = this.ignorePagination.currPage - 1;
          this.getUserIgnore();
          return;
        }
        this.ignoreList = res.data;
        const { curPage, count } = res;
        this.ignorePagination.currPage = curPage;
        this.ignorePaginationCount = count;
      } finally {
        this.ignoreListLoading = false;
      }
    },
    async addUserIgnore(item) {
      await this.$api.market.addFissionFlow({ userId: item.id });
      this.getUserIgnore();
    },
    delUserIgnore(id) {
      this.$confirm("该操作会删除该用户, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "error",
      }).then(async () => {
        await this.$api.market.delFissionFlow(id);
        this.getUserIgnore();
      });
    },
    async searchUserList(page) {
      if (!this.inputValue) {
        this.$message.warning("搜索名称不能为空");
        return;
      }
      const param = {
        currPage: page || this.userPagination.curPage,
        limit: this.userPagination.size,
        searchName: this.inputValue,
      };
      this.userListLoading = true;
      try {
        const res = await this.$api.market.getFissionFlowList(param);
        this.userList = res.data;
        const { curPage, count, size } = res;
        this.userPagination = {
          curPage,
          count,
          size,
        };
      } finally {
        this.userListLoading = false;
      }
    },
  },
  destroyed() {},
};
</script>
    
<style lang='scss' scoped>
.title {
  background: #eceafa;
}
.dia-title {
  background: #7f76bd;
  color: #fff;
  border-radius: 4px;
  width: 444px;
  height: 24px;
}

.table-header {
  box-sizing: border-box;
  padding-left: 50px;
  color: #999999;
  font-size: 14px;
  text-align: center;
}
.item {
  color: rgba(51, 51, 51, 1);
  height: 24px;
  line-height: 24px;
  font-size: 14px;
  border-radius: 4px;
  margin-top: 6px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  .item-index {
    margin-left: 10px;
    min-width: 18px;
    height: 18px;
    border-radius: 4px;
    border: 2px solid rgba(197, 192, 224, 1);
    color: rgba(197, 192, 224, 1);
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
  }
}
.del-btn {
  width: 48px;
  height: 20px;
  text-align: center;
  color: #f394ac;
  line-height: 20px;
  background: #ffffff;
  border-radius: 4px;
  border: 1px solid #f394ac;
}
.other {
  font-size: 14px;
  width: 64px;
  height: 20px;
  text-align: center;
  line-height: 20px;
  border-radius: 4px;
}
.left-wrap {
  width: 40%;
  padding-right: 10px;
  border-right: 1px solid #c5c0e0;
  .title {
    display: inline-block;
    padding: 3px 16px;
    margin-bottom: 8px;
    border-radius: 3px;
  }
}
.left-list {
  height: 330px;
  overflow-y: scroll;
  padding-right: 10px;
  margin: 10px 0;
  .add-btn {
    background-image: url("../../../../assets/add_btn_n.png");
    background-repeat: no-repeat;
    background-size: cover;
    text-align: center;
    width: 18px;
    height: 24px;
  }
}
.shenglue {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  word-break: break-all;
}
</style>